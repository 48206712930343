import { memo, useContext, useEffect, useRef, useState } from "react";
import AppContext from "src/components/AppContext";
import { graphqlApiCall } from "src/services/graphql.service";
import DoughnutChartJS, {
  DoughnutData,
  DoughnutDataSetType,
} from "helpers/hps/charts/DoughnutChartJS";

const Home = () => {
  const context = useContext(AppContext);
  const { setShowLoader, selectedDashboards } = context.state;
  const donutChartRef = useRef(null);
  const [chartData, setChartData] = useState<DoughnutData>();

  const fetchSummary = async () => {
    if (setShowLoader) setShowLoader(true);
    const response = await graphqlApiCall({
      node: "allSummaryOfProbableStocks",
      columns: ["total", "status"],
    });
    const newArray: { name: string; value: number }[] = [];
    response.map((x: { status: string; total: string }) =>
      newArray.push({
        name: x.status,
        value: parseInt(x.total),
      }),
    );
    const total =
      newArray.filter((x) => x.name === "Total").map((x) => x.value)[0] -
      newArray.filter((x) => x.name === "Open").map((x) => x.value)[0];
    const filteredData = newArray.filter(
      (x) => x.name !== "Open" && x.name !== "Total",
    );
    const labels = filteredData.map((d) => `${d.name} (${d.value})`);
    const data = filteredData.map((d) => +((d.value / total) * 100));
    const sum = data.reduce(
      (v1: number, v2: number) => +v1.toFixed(2) + +v2.toFixed(2),
    );
    data[0] += 100 - sum;
    filteredData.map((_d, index) => (data[index] = +data[index].toFixed(2)));
    const datasets: DoughnutDataSetType[] = [
      {
        label: "",
        data: data,
        borderColor: ["#F1F395", "#FF5252", "#20AC91"],
        backgroundColor: ["#F1F395", "#FF5252", "#20AC91"],
      },
    ];
    setChartData({
      labels,
      datasets: datasets,
    });
    if (setShowLoader) setShowLoader(false);
  };

  useEffect(() => {
    if (selectedDashboards.length == 0) fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedDashboards.length > 0 ? (
        selectedDashboards.map((d) => (
          <div key={d.id} className="row">
            <div className="col p-4" style={{ minHeight: "90vh" }}>
              <iframe id={d.id} src={d.url} width="100%" height="100%"></iframe>
            </div>
          </div>
        ))
      ) : (
        <div
          className="col-12 mt-2 data-grid"
          ref={donutChartRef}
          key="donut-chart"
        >
          {/* <DountChart data={data} width={width} height={height} total={total} /> */}
          {chartData && <DoughnutChartJS data={chartData} />}
        </div>
      )}
    </>
  );
};
export default memo(Home);
